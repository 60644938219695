import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  Typography,
} from '@mui/material';
import { useState } from 'react';

const BotProductItem = ({ product, handleSelect, onClick, showCheckbox = false }) => {
  const [selected, setSelected] = useState(product?.selected || false);

  const handleCheck = (e) => {
    e.stopPropagation(); // Ngăn sự kiện click lan ra ngoài
    setSelected((prev) => !prev);
    if (handleSelect) {
      handleSelect(product, !selected);
    }
  };

  return (
    <Card variant="outlined" sx={{ position: 'relative' }} onClick={onClick}>
      <CardActionArea>
        <Box sx={{ position: 'relative' }}>
          {showCheckbox && (
            <Box
              sx={{ 
                position: 'absolute', 
                top: 4, 
                right: 4,
                zIndex: 1 
              }}
              display={'flex'}
              flexDirection={'column'}
            >
              <Checkbox
                sx={{ bgcolor: 'white', '&:hover': { bgcolor: 'white' } }}
                onClick={handleCheck}
                checked={selected}
              />
            </Box>
          )}
          <CardMedia
            sx={{ objectFit: 'contain' }}
            component="img"
            height="140"
            image={product?.image}
            alt={product?.name}
          />
        </Box>
        <CardContent sx={{ py: 1, px: 2, mb: 0, mt: 1 }}>
          <Typography
            gutterBottom
            variant="h6"
            sx={{ fontSize: 16 }}
            component="div"
            className="max-line-1"
          >
            {product?.name}
          </Typography>
          {/* <Typography variant="body2" color="text.secondary" className="max-line-2">
            {product?.description}
          </Typography> */}
          {/* <Typography variant="body1" color="primary" fontWeight="bold">
            {product?.price?.toLocaleString('vi-VN')} VND
          </Typography> */}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default BotProductItem;