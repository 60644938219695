import React, { useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material';
import MainTitle from '../../components/partials/MainTitle';
import CardContentHeader from '../../components/cards/CardContentHeader';
import BotProductUpload from './BotProductUpload';
import { useModal } from '../../hooks/useModal';
import BotProductItem from './BotProductItem';
import { useParams } from 'react-router-dom';
import botService from '../../services/botService';

const BotProductsStorage = () => {
  const [products, setProducts] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSelectionMode, setIsSelectionMode] = React.useState(false);
  const { id: botId } = useParams();
  
  const productUploadModal = useModal('bot-product-upload', BotProductUpload);

  const fetchProducts = async () => {
    try {
      setIsLoading(true);
      const response = await botService.getBotById(botId);
      setProducts(response?.products || []);
    } catch (error) {
      console.error('Error fetching products:', error);
      setProducts([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (botId) {
      fetchProducts();
    }
  }, [botId]);

  const handleOpenProductUploadModal = () => {
    productUploadModal.openModal({ botId });
  };

  const handleOpenEditProductModal = (productItem) => {
    if (!productItem?.product) return;

    productUploadModal.openModal({ 
      botId,
      product: {
        ...productItem.product,
        images: productItem.product.images?.map(img => ({
          preview: img?.url || '',
          file: null,
          fileId: img?.fileId || '',
          name: img?.name || '',
          url: img?.url || ''
        })) || []
      }
    });
  };

  const handleUpdateSelectedProducts = async () => {
    const selectedProductIds = products
      ?.filter(item => item?.selected && item?.product?.id)
      ?.map(item => item.product.id) || [];     
    
    if (!selectedProductIds.length) {
      console.log('No products selected');
      return;
    }
    
    try {
      setIsLoading(true);
      await botService.tranningProducts(botId, { productIds: selectedProductIds });
      setIsSelectionMode(false);
    } catch (error) {
      console.error('Error training products:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectProduct = (productItem, isSelected) => {
    if (!productItem?.product?.id) return;
    
    const updatedProducts = products.map(item => 
      item?.product?.id === productItem?.product?.id 
        ? { ...item, selected: isSelected }
        : item
    );
    setProducts(updatedProducts);
  };

  const handleToggleSelectionMode = () => {
    setIsSelectionMode(!isSelectionMode);
  };

  return (
    <Box>
      <MainTitle
        title="Thư viện sản phẩm"
        buttonName="Thêm sản phẩm mới"
        onClick={handleOpenProductUploadModal}
      />
      <Card variant="outlined" sx={{ height: '100%' }}>
        <CardContent>
          <CardContentHeader
            title={'Danh sách sản phẩm'}
            action={
              <Button 
                onClick={isSelectionMode ? handleUpdateSelectedProducts : handleToggleSelectionMode} 
                variant="contained" 
                size="small"
              >
                {isSelectionMode ? 'Bắt đầu training' : 'Chọn sản phẩm training'}
                {isLoading && (
                  <CircularProgress
                    sx={{ ml: 1 }}
                    color="inherit"
                    size={15}
                    thickness={5}
                    variant="indeterminate"
                  />
                )}
              </Button>
            }
          />
          {isSelectionMode && (
            <Typography 
              variant="body2" 
              color="text.secondary" 
              sx={{ mb: 2, mt: 1 }}
            >
              Vui lòng chọn các sản phẩm bạn muốn training
            </Typography>
          )}
          <Grid spacing={2} container>
            {products?.map((productItem, index) => (
              productItem?.product && (
                <Grid item xs={12} sm={6} md={4} lg={3} key={productItem.product.id || index}>
                  <BotProductItem 
                    product={{
                      ...productItem.product,
                      selected: productItem.selected,
                      image: productItem.product.images?.[0]?.url || ''
                    }}
                    showCheckbox={isSelectionMode}
                    handleSelect={(_, isSelected) => 
                      handleSelectProduct(productItem, isSelected)
                    }
                    onClick={() => handleOpenEditProductModal(productItem)}
                  />
                </Grid>
              )
            ))}
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default BotProductsStorage; 