import httpRequest from '../utils/httpRequest';

const productService = {
  createProduct: (botId, values) => {
    const formData = new FormData();
    values.images.forEach((image) => {
      formData.append('images', image);
    });
    formData.append('name', values.name);
    formData.append('description', values.description);
    formData.append('botId', botId);
    return httpRequest
      .post('/products', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((res) => res.data);
  },

  updateProduct: (productId, values) => {
    const formData = new FormData();
    values.images.forEach((image) => {
      if (image instanceof File) {
        formData.append('images', image);
      } else if (image.fileId) {
        formData.append('existingImages', image.fileId);
      }
    });
    formData.append('name', values.name);
    formData.append('description', values.description);
    formData.append('botId', values.botId);
    return httpRequest
      .put(`/products/${productId}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((res) => res.data);
  },
};

export default productService;