import React, { useState, useEffect } from 'react';
import { VietQR } from 'vietqr';
import {

  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';

const QrCode = ({ amount = 1000, message = 'Schat', open, onClose }) => {
  const accountNumber = '68555585555';
  const bankCode = '970423';
  const accountName = 'CONG TY CO PHAN PHAT TRIEN TRI TUE NHAN TAO VKS AI';
  const vietQR = new VietQR({
    clientID: process.env.REACT_APP_CLIENT_ID_VIET_QR,
    apiKey: process.env.REACT_APP_API_KEY_VIET_QR,
  });
  const user = useSelector((state) => state.auth.user);
  const [qrCodeData, setQrCodeData] = useState(null);
  
  useEffect(() => {
      if (amount && message) {
          generateQRCode();
        }
  }, [amount, message]);

  const generateQRCode = () => {
    vietQR
      .genQRCodeBase64({
        bank: bankCode,
        accountName,
        accountNumber,
        amount: amount ? parseInt(amount) : 0,
        memo: message + ` ${user.name}`,
        template: 'compact',
      })
      .then((data) => {
        setQrCodeData(data.data.data.qrDataURL);
      })
      .catch((err) => {
        console.error('Lỗi khi tạo mã QR:', err);
      });
  };

  return (
    <div>
      <Dialog open={open} maxWidth="sm" fullWidth>
        <DialogTitle>
          <Typography variant="body6">Mã QR</Typography>
        </DialogTitle>
        <DialogContent>
          {qrCodeData ? (
            <img src={qrCodeData} alt="QR code" style={{ width: '100%', height: 'auto' }} />
          ) : (
            <Typography variant="body2">
              {amount == 0 ? (
                <>
                  <div>
                    Vui lòng liên hệ{' '}
                    <a
                      href="tel:0836991919"
                      style={{ fontWeight: 'bold', textDecoration: 'none', color: '#24417C' }}
                    >
                      0836991919
                    </a>{' '}
                    để được hỗ trợ
                  </div>
                </>
              ) : (
                <>Đang tạo mã QR...</>
              )}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Đóng
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default QrCode;
