import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import FormGroup from '../../components/forms/FormGroup';
import FormLabel from '../../components/forms/FormLabel';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { styled } from '@mui/material/styles';
import productService from '../../services/productService';
import botService from '../../services/botService';
import { useModal } from '../../hooks/useModal';
import CircularProgress from '@mui/material/CircularProgress';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Vui lòng nhập tên sản phẩm'),
  description: Yup.string().required('Vui lòng nhập mô tả sản phẩm'),
  images: Yup.array().min(1, 'Vui lòng chọn ít nhất 1 ảnh'),
});

const BotProductUpload = ({ handleCloseModal }) => {
  const theme = useTheme();
  const botProductUploadModal = useModal('bot-product-upload');
  const { botId, product } = botProductUploadModal.data;
  const isEditMode = !!product;
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    if (handleCloseModal) {
      handleCloseModal();
    }
    botProductUploadModal.closeModal();
  };

  const initialValues = {
    name: product?.name || '',
    description: product?.description || '',
    images: product?.images || [], // Sẽ chứa cả ảnh cũ và mới
  };

  const addProductToBot = async (botId, body) => {
    setIsLoading(true);
    try {
      const res = await botService.addProduct(botId, body);
      if (res) {
        handleClose();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const updateProduct = async (productId, body) => {
    setIsLoading(true);
    try {
      const res = await productService.updateProduct(productId, body);
      if (res) {
        handleClose();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      console.log('Form values:', {
        name: values.name,
        description: values.description,
        images: values.images,
        isEditMode,
        productId: product?.id
      });

      if (isEditMode) {
        // Cập nhật sản phẩm
        await updateProduct(product.id, {
          name: values.name,
          description: values.description, 
          images: values.images,
          botId
        });
      } else {
        // Tạo mới sản phẩm
        const res = await productService.createProduct(botId, values);
        if (res?.product?.id) {
          await addProductToBot(botId, {
            productId: res.product.id,
          });
        }
      }
    } catch (error) {
      console.error('Error saving product:', error);
    } finally {
      setSubmitting(false);
    }
  };

  const getImagePreview = (image) => {
    if (image.url) {
      // Ảnh đã tồn tại
      return image.url;
    }
    // Ảnh mới upload
    return URL.createObjectURL(image);
  };

  return (
    <Card sx={{ bgcolor: theme.palette.background.default }}>
      <CardHeader
        sx={{ pb: 0, '& .MuiCardHeader-title': { fontSize: 18 } }}
        title={isEditMode ? 'Cập nhật sản phẩm' : 'Thêm sản phẩm mới'}
        action={
          <IconButton onClick={handleClose} sx={{ borderRadius: 2 }} color="error">
            <CloseIcon />
          </IconButton>
        }
      />
      <CardContent>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, errors, touched, handleChange, handleBlur, setFieldValue, isSubmitting }) => (
            <Form>
              <FormGroup>
                <FormLabel required>Tên sản phẩm</FormLabel>
                <TextField
                  fullWidth
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                />
              </FormGroup>

              <FormGroup>
                <FormLabel required>Ảnh sản phẩm</FormLabel>
                <Box display="flex" flexWrap="wrap" gap={2}>
                  {values.images.map((image, index) => (
                    <Box
                      key={index}
                      position="relative"
                      width={150}
                      height={150}
                      border={`1px solid ${theme.palette.divider}`}
                      borderRadius={1}
                      overflow="hidden"
                    >
                      <img
                        src={getImagePreview(image)}
                        alt={`Preview ${index + 1}`}
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                      />
                      <IconButton
                        size="small"
                        sx={{
                          position: 'absolute',
                          top: 4,
                          right: 4,
                          bgcolor: 'background.paper',
                          '&:hover': { bgcolor: 'error.light' },
                        }}
                        onClick={() => {
                          const newImages = values.images.filter((_, i) => i !== index);
                          setFieldValue('images', newImages);
                        }}
                      >
                        <DeleteOutlineIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  ))}
                  <Button
                    component="label"
                    sx={{
                      width: 150,
                      height: 150,
                      border: `1px dashed ${theme.palette.primary.main}`,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: 1,
                    }}
                  >
                    <CameraAltIcon sx={{ color: theme.palette.primary.main }} />
                    <Typography variant="caption" color="primary">
                      Thêm ảnh
                    </Typography>
                    <VisuallyHiddenInput
                      type="file"
                      accept="image/*"
                      multiple
                      onChange={(e) => {
                        const newFiles = Array.from(e.target.files);
                        setFieldValue('images', [...values.images, ...newFiles]);
                      }}
                    />
                  </Button>
                </Box>
                {touched.images && errors.images && (
                  <Typography color="error" variant="caption">
                    {errors.images}
                  </Typography>
                )}
              </FormGroup>

              <FormGroup>
                <FormLabel required>Mô tả sản phẩm</FormLabel>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.description && !!errors.description}
                  helperText={touched.description && errors.description}
                />
              </FormGroup>

              <Box mt={2} display="flex" justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting || isLoading}
                >
                  {isEditMode ? 'Cập nhật' : 'Thêm mới'}
                  {(isSubmitting || isLoading) && (
                    <CircularProgress
                      size={20}
                      thickness={5}
                      sx={{ ml: 1 }}
                      color="inherit"
                    />
                  )}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
};

export default BotProductUpload; 